@font-face 
{ 
    font-family: "Manrope";  
    src: url("https://kellysims.com/assets/webfonts/Manrope-Regular.woff2")format("woff2");
    font-weight: normal;
    font-display: swap;
}
@font-face 
{ 
    font-family: "Manrope";  
    src: url("https://kellysims.com/assets/webfonts/Manrope-ExtraBold.woff2")format("woff2");
    font-weight: Bold;
    font-display: swap;
}
html, 
body {
    height: 100%;
    background-color: rgb(245 245 244);
}
body {
  font-weight: normal;
  font-family: "Manrope";
  padding: 0;
  margin: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
h1 {
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.625;
  color: rgb(38, 38, 38);
  a, a:visited, a:hover, a:active {
    text-decoration: none;
    color: rgb(38, 38, 38);
  }
  @media (min-width: 1024px){
    font-size: 1.875rem;
    padding-top: 0;
    padding-bottom: 0;
  }
}
h2 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  font-size: 1.675rem;
  font-weight: 700;
  line-height: 1.625;
  color: rgb(38,38,38);
  a, a:visited, a:hover, a:active {
    text-decoration: underline;
    color: rgb(38,38,38);
  }
  &.section-lead {
    text-align: center;
    padding: 1rem;
    margin: 1rem;
    a {
      text-decoration: none;
    }
  }
}
h3 {
  color: rgb(38,38,38);
  a, a:visited, a:hover, a:active {
    text-decoration: underline;
    color: rgb(38,38,38);
  }
}
a, a:visited, a:hover, a:active {
      color: rgb(38,38,38);
      text-decoration: underline;
}
blockquote {
  display:block;
  padding: .25rem 1rem;
  margin: 1rem 1rem 1rem 0;
  background: rgb(241,233,246);
  background: linear-gradient(180deg, rgba(241,233,246,1) 0%, rgba(241,233,246,0.5046393557422969) 100%);
  border-radius: 3px;
  & em {
    display:block;
    padding-top: 1rem;
    margin-top: .5rem;
    border-top: 1px solid rgb(229, 202, 246);
  }
}

.main-nav {
  background-repeat: no-repeat;
  background-image: linear-gradient(0deg, rgba(245,245,244,1) 25%, rgba(245,245,244,0.535171568627451) 100%), url(/assets/site/bg-07.jpg);
  background-position: fixed;
  background-size: 100% 100%;
  justify-content: flex-start;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  a, a:visited, a:hover, a:active {
    color: rgb(115 115 115);
    display: inline-block;
    margin-right: 1.5rem;
    margin-bottom: 1.5rem;
    text-decoration: none;
    line-height: 1;
    &:hover {
      color: rgb(0,0,0);
    }
    @media (min-width:768px) {
      margin-right: 1rem;
    }
  }
  a.top {
    font-weight: 900;
    font-size: 1.3rem;
    color: rgb(38, 38, 38);
    margin-right: 0;
    @media (min-width:472px) {
      margin-right: 3rem;
    }
  }
}
.nav-active {
  border-bottom: 1px solid rgb(38, 38, 38);
}

/** Homepage layout **/

.notebook {
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  max-width: 1024px;
  padding: 0 2.5rem 2rem 2.5rem;
  @media (min-width:768px) {
    display: grid;
    grid-auto-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2vw;
  }
  .post-image img {
    border-radius: 0.375rem;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    box-sizing: border-box;
    max-width: 100%;
    display: block;
    width: 100%; height: 237px; object-fit: cover; object-position: 50% 50%;
  }
  .note {
    text-align: center;
  }
}

.content{
  line-height: 24px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  max-width: 1024px;
  position: relative;
  padding: 0 2.5rem 2rem 2.5rem;
  @media (min-width:768px) {
    padding-bottom: 2rem;
  }
  p {
    font-size: 1.05rem;
    line-height: 1.5;
  }
}
.content.page {
  @media (min-width:768px) {
    margin-top: 4rem;
    padding-top: 1.5rem;
  }
}
.content.post-list {
  max-width: 56rem;
}
.content .post {
  margin-bottom: 6rem;
}
.content .post-title {
  margin-bottom: 1rem;
  padding: 0;
}
.post.link {
  margin-bottom: 3rem;
}
.post-link {
    border-bottom: 1px solid #aaa3a3;
}
.link-icon {
  margin-left:.5rem;
  font-size: 1.5rem;
}
.post.link h1::after {
    background-image: url(data\:image\/svg\+xml\;base64\,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzAwMCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjIiIHZpZXdCb3g9IjAgMCAyNCAyNCI\+PHBhdGggZD0iTTE4IDEzdjZhMiAyIDAgMCAxLTIgMkg1YTIgMiAwIDAgMS0yLTJWOGEyIDIgMCAwIDEgMi0yaDZNMTUgM2g2djZNMTAgMTQgMjEgMyIvPjwvc3ZnPg\=\=);
    background-position: 50% 50%;
    background-size: cover;
    content: "";
    height: .85rem;
    margin: .05rem .2rem .05rem 0;
    width: 1rem;
}

.post-list.archive {
  h2 {
    border-bottom: 1px solid rgb(204, 207, 212);
    padding-bottom: .25rem;
    margin-bottom: 3rem;
  }
  header {
    margin-bottom: 0;
  }
  article {
    margin-bottom: 3rem;
    padding-left: 2rem;
  }
  article.has-image {
    @media (min-width:768px) {
      display: grid;
      grid-auto-rows: 1fr;
      grid-template-columns: 1fr 3fr;
      grid-gap: 2vw ;
    }
    .post-lead-image img {
      border-radius: 0.375rem;
      box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
      box-sizing: border-box;
      max-width: 100%;
      display: block;
      height: 185px;
      object-fit: cover; 
      object-position: 50% 50%;
      margin-top: 1rem;
    }
  }
}

/** Site Footer **/

footer {
  line-height: 24px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 4rem;
  padding-top: 6rem;
  display: grid;
  column-gap: 1rem;
  grid-template-columns: auto;
  text-align: center;
  background-repeat: no-repeat;
  background-image: linear-gradient(180deg, rgba(245,245,244,1) 26%, rgba(245,245,244,0.9192051820728291) 60%, rgba(245,245,244,0.635171568627451) 100%), url(/assets/site/bg-05.jpg);
  background-position: contain;
  background-size: contain;
  background-size: 100% 100%;
  margin-top: auto;
  width:100%;
  .title {
    display: block;
    font-size: 1.25rem;
    text-decoration: none;
    color: rgb(38, 38, 38);
    font-weight: 900;
  }
  .tagline {
    margin-top: 1rem;
    font-size: 0.875rem;
    color: rgb(51 65 85);
  }
  section {
    padding: 0 2.5rem;
    h2 {
      display: block;
      font-size: 1rem;
      text-decoration: none;
      color: rgb(38, 38, 38);
      line-height: 1;
      padding: 0;
      svg {
        height: .75rem;
      }
      a {
        color: inherit;
        text-decoration: none;
      }
    }
  }

  nav {
    margin-bottom: 2rem;
      a, a:visited, a:hover, a:active {
        display: block;
        color: rgb(82, 82, 82);
        text-decoration: none;
        margin-bottom: 0.25rem;
    }
  }
  @media (min-width: 1024px){
    grid-template-columns: auto auto auto;
    text-align: left;
  }
}

/** about me **/
img[src$='#about-me'] {
  max-width: 20rem;
  width: 33.333333%;
  display: inline-block;
  float: right;
  margin-bottom: 1.25rem !important;
  margin-top: 0.5rem !important;
  margin-left: 1.25rem !important;
  border-radius: 0.375rem !important;
  height: auto;
  border-radius: 0.375rem;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  box-sizing: border-box;
    @media (min-width: 1024px){
      max-width: 25%;
    }
}

/** Posts **/
.content article {
  max-width: 64rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4rem;
  position: relative;
}
.post-separator {
  position: relative;
  margin-top: 4rem;
  margin-bottom: 4rem;
  height: 1px;
}
.post-separator:before {
    content: "";
    position: absolute;
    top: 0;
    left: 5%;
    right: 5%;
    width: 90%;
    height: 1px;
    background-image: linear-gradient(to right, transparent, rgb(82,82,82), transparent);
}
.post-separator:after {
    font-family: cursive;
    content: '.';
    display: block;
    width: 2%;
    padding: 0 2rem;
    color: rgb(82,82,82);
    font-size: 2rem;
    text-align: center;
    position: absolute;
    top: 0;
    line-height: 1;
    left: 50%;
    background-color: rgb(245 245 244);
    transform: translate(-50%, -75%);
}
.post header, .post-list header {
  text-align: center;
  margin-bottom: 3rem;
  h1 {
    font-size: 1.875rem;
    font-weight: 700;
    color: rgb(38, 38, 38);
  }
}
.post-meta {
  margin-bottom: 2rem;
  padding-bottom: .5rem;
  text-transform: none;
  p {
      font-size: 0.875rem;
      margin:0;
      padding:0;
      a, a:visited, a:hover, a:active {
            color: rgb(38,38,38);
            text-decoration: none;
      }
  }
}
.archive .post-meta {
  margin-bottom: 0;
}
.post-lead-image {
  border-radius: 0.375rem;
  height: auto;
  margin-bottom: 0.25rem;
  text-align: center;
  img {
    border-radius: 0.375rem;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    box-sizing: border-box;
    max-width: 100%;
  }
  .lead-image-caption {
    font-size: 1rem;
    color: rgb(120 113 108);
    a, a:visited, a:hover, a:active {
      color: rgb(254, 0,0);
      text-decoration: underline;
    }
  }
}

.post-content {
  max-width: 48rem;
  margin-right: auto;
  margin-left: auto;
  img {
    border-radius: 0.375rem;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    box-sizing: border-box;
    max-width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  img + em {
    font-size: 1rem;
    color: rgb(120 113 108);
    text-align: center;
    display: block;
    margin-top: -1rem;
    margin-bottom: 2.5rem;
  }
  .video {
    position: relative;
    padding-bottom: 4rem;
    aspect-ratio: 16 / 9;
    max-width: 100%;
  }
  iframe {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0.375rem;
  }
  h2 {
    margin-bottom: 0.5rem;
    margin-top: 2rem;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 1.625;
    & a, a:visited, a:hover, a:active {
      text-decoration: underline;
    }
  }
  hr {
    position: relative;
    margin-top: 3rem;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    height: 1px;
    border: none;
    overflow: visible;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 5%;
        right: 5%;
        width: 90%;
        height: 1px;
        background-image: linear-gradient(to right, transparent, rgb(82,82,82), transparent);
    }
    &:after {
        font-family: cursive;
        content: '&';
        display: block;
        width: 2%;
        padding: 0 2rem;
        color: rgb(82,82,82);
        font-size: 1rem;
        text-align: center;
        position: absolute;
        top: 0;
        left: 50%;
        background-color: rgb(245 245 244);
        transform: translate(-50%, -45%);
    }
  }
}
.related-posts{
  max-width: 48rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4rem;
  position: relative;
    a, a:visited, a:hover, a:active {
        display: block;
        color: rgb(82, 82, 82);
        text-decoration: none;
        margin-bottom: 0.25rem;
    }
}

/** Pagination **/
.pagination {
  max-width: 48rem;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  a, a:visited, a:active {
    color: rgb(51 65 85);
    text-decoration: none;
    border-bottom: 1px solid rgb(245 245 244);
  }
  a:hover {
    border-color: rgb(51 65 85);
  }
}
.page-nav {
  margin: 2rem auto;
}
.archives {
  margin: .25rem 2rem;
}
.previous, .next {
  margin: .25rem 2rem;
}
.currentpage {
  margin-right: auto;
  margin-left: auto;
  font-weight: bold;
  padding: .25rem 2%;
  border-bottom: 1px solid rgb(51 65 85);
}
.pagenum {
  margin-right: auto;
  margin-left: auto;
  padding: .25rem 2%;
}

/** Photo posts **/
.photo {
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    > * {
      padding: 1rem;
    }
  }
}
.photo header {
  @media (min-width: 768px) {
    margin-bottom: 3rem;
  }
}
.photo .post-content {
  text-align: left;
  h1 {
    font-size: 1.875rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
    margin-top: .5rem !important;
    padding: 0;
  }
}
/** Blogroll **/
.post-list .blog {
  margin: 0 0 2.5rem 0;
}
.blog h2 {
  padding: 0;
  font-size: 1.175rem;
  font-weight: 700;
  line-height: 1.175;
}
.blog h2 a {
  text-decoration: none;
}